.loader {
    position: relative;
    width: 100%;
    height: 100%;

    min-height: 300px;
    min-width: 200px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.loader img {
    /* width: 200px; */
    height: 150px;
}

.isotipo-loader {
    animation: palpitar 1s ease-in-out infinite;
}

@keyframes palpitar {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}