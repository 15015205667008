@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
.lastProyects{
    color: #3c74c3!important;
}
.logoPanel{
    width: 60px;
}

.logoPanel2{
    width: 90%;
}
.buttonspanel{
    display: flex!important;
    gap: 20px!important;
}

#fuentes{
    font-family: 'Montserrat'!important;
    font-weight: 600!important;
}
.h3.mb-0.text-gray-800{
    font-family: 'Montserrat'!important;
}
.bg-gradient-primary {
    background-color: #67C87F;
    background-image: linear-gradient(180deg, #3C74C3 10%, #67C87F 100%)!important;
    background-size: cover;
}
.sidebar-card.d-none.d-lg-flex .btn-success {
    --bs-btn-color: #fff;
    --bs-btn-bg: #67C87F;
    --bs-btn-border-color: #67C87F;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #67C87F;
    --bs-btn-hover-border-color: #67C87F;
    --bs-btn-focus-shadow-rgb: 60, 153, 110;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #67C87F;
    --bs-btn-active-border-color: #67C87F;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #67C87F;
    --bs-btn-disabled-border-color: #67C87F;
}
.d-none.d-sm-inline-block.btn.btn-sm.btn-primary.shadow-sm{
    background: #3C74C3;
    border-style: none;
}
#tituloPrincipal{
    font-size: 26px;
    color: #3C74C3!important;
    margin-left: 1%;
}
.bg-danger {
    --bs-bg-opacity: 1;
    background-color: rgb(195 195 195) !important;
}
.card.border-left-primary.shadow.h-100.py-2 .card-body{
    margin: auto!important;
}
.card.border-left-primary.shadow.h-100.py-2 .card-body button{
    color:white;
    background: #3C74C3;
    border-style: none;
    border-radius: 10px;
    padding: 5px;
    margin: auto;
    display: flex;
    font-size: small;
}
.text-m.font-weight-bold.text-uppercase.mb-1{
    color:#3C74C3!important;
}
h6{
    color:#3C74C3!important;
}
.modal-header {
    background:#3C74C3!important;
}


@media screen and (max-width: 600px) {
    #tituloPrincipal {
        font-size: 10px;
    }
}
