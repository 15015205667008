.bg-gradient-primary {
    background-color: #67C87F;
    background-image: linear-gradient(180deg, #3C74C3 10%, #67C87F 100%) !important;
    background-size: cover;
}

/* .container-fluid{
    height: auto;
} */

.navbar-nav .nav-item a {
    color: white !important;
}

.navbar-nav .nav-item .active {
    font-weight: bold;
}

#mainNavbar .nav-link {
    display: flex;
    justify-content: center;
    width: 100%;
}

@media (max-width: 991.98px) {
    #mainNavbar .container-fluid {
        justify-content: center;
    }

    #mainNavbar .navbar-toggler {
        right: 10px;
        top: 10px;
        position: absolute;
    }
}