#container-water {
  margin: 0;
  display: flex;
  background: linear-gradient(to bottom, #e6f1f5, #ffffff);
}
canvas {
  display: block;
}
#info {
  position: absolute;
  top: 10px;
  width: 100%;
  text-align: center;
  color: white;
  font-family: Arial, sans-serif;
  pointer-events: none;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.8);
  z-index: 100;
}
#loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 20px;
  border-radius: 10px;
  font-family: Arial, sans-serif;
  z-index: 100;
}
#controls {
  width: 160px;
  padding: 10px;
  background: linear-gradient(to bottom, #333333, #666666);
  font-family: Arial, sans-serif !important;
  font-size: 12px !important;
  color: #ffffff !important;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  position: absolute;
}
h3.water-particle,
h4.water-particle {
  font-size: initial;
  color: #ffffff !important;
  margin: 5px 0;
  text-align: center;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}
h4.water-particle {
  margin-top: 12px;
  padding-top: 8px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
button.water-particle {
  width: 100%;
  padding: 8px;
  margin-bottom: 8px;
  cursor: pointer;
  font-size: 11px;
  background-color: #eeeeee;
  color: #333;
  border: none;
  border-radius: 5px;
  transition: background-color 0.2s;
}
button.water-particle:hover {
  background-color: #ffffff;
}
button.water-particle.active {
  background-color: #aaaaaa;
  color: #ffffff;
  font-weight: bold;
}
#tooltip {
  position: absolute;
  background-color: rgba(50, 50, 50, 0.9);
  color: white;
  padding: 8px;
  border-radius: 4px;
  font-family: Arial, sans-serif;
  font-size: 12px;
  z-index: 100;
  pointer-events: none;
  display: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(200, 200, 200, 0.3);
  min-width: 120px;
  text-align: center;
  transition: transform 0.05s ease-out;
  transform-origin: top left;
  backdrop-filter: blur(4px);
}
#zoomIndicator {
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid #cccccc;
  box-shadow: 0 0 10px rgba(204, 204, 204, 0.8);
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 101;
  display: none;
  opacity: 0.8;
}
#zoomIndicator::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #ffffff;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
