#imgHerramientas{
    width: 50px;
    height: 50px;
}

#textoHerramientas{
    color: #3C74C3;
    font-weight: 500;

}
a {
    text-decoration: none!important;
    color: inherit; 

}