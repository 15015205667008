/* SelectSuelos.css */

.select-suelos {
    position: fixed;
    top: 120px;
    right: 20px;
    background-color: rgb(255, 255, 255);
    border-radius: 15px;
    padding: 20px;
    z-index: 1000;
    width: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .select-container {
    margin-bottom: 15px;
  }
  
  .select-container label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .select-container select {
    width: 100%;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }

  label{
    color: black;
    font-weight: 500!important;
  }
  
  .matriz-inputs{
    display: flex;
    gap: 10px;
  }

  .inputs-matriz{
    background:white;
    border-style: solid;
    border-color: #CCCCCC;
    border-width: 0.5px;
    color: black;
  }

  .send-options{
    background: #3C74C3;
    color: white;
    margin: auto;
    display: block;
    border-radius: 10px;
    padding: 10px;
  }